// the default options for the Tags and the Categories

export interface TagOption {
  readonly value: string;
  readonly label: string;
}

export const TagOptions: readonly TagOption[] = [
  { value: "Exhibitor ", label: "Exhibitor" },
  { value: "Academic", label: "Academic" },
  {
    value: "Broadcasting Media production",
    label: "Broadcasting Media production",
  },
  {
    value: "Business incubators and accelerators",
    label: "Business incubators and accelerators",
  },
  {
    value: "Content Creation / Production",
    label: "Content Creation / Production",
  },
  { value: "prayer-room", label: "Prayer Room" },
  { value: "Media", label: "Media" },
  { value: "info-desk", label: "Info Desk" },
  {
    value: "Broadcast equipment manufacturer",
    label: "Broadcast equipment manufacturer",
  },
  {
    value: "Content Management / Post-Production",
    label: "Content Management / Post-Production",
  },
  {
    value: "OTT / Content Delivery / Broadcast",
    label: "OTT / Content Delivery / Broadcast",
  },
  { value: "Production films & series", label: "Production films & series" },
];

export interface CatOption {
  readonly value: string;
  readonly label: string;
  readonly tag: string;
}

export const CatOptions: readonly CatOption[] = [
  { value: "Cafes & Bakeries", label: "Cafes & Bakeries", tag: "dine-in" },
  { value: "Food Court", label: "Food Court", tag: "dine-in" },
  { value: "Restaurants", label: "Restaurants", tag: "dine-in" },
  {
    value: "Speciality Food / Sweets",
    label: "Speciality Food / Sweets",
    tag: "dine-in",
  },
  { value: "Accessories", label: "Accessories", tag: "shop" },
  {
    value: "Anchors / Department Store",
    label: "Anchors / Department Store",
    tag: "shop",
  },
  { value: "Beauty Clinic", label: "Beauty Clinic", tag: "shop" },
  {
    value: "Electronics & Computers",
    label: "Electronics & Computers",
    tag: "shop",
  },
  { value: "Entertainment", label: "Entertainment", tag: "shop" },
  { value: "Eyewear", label: "Eyewear", tag: "shop" },
  { value: "Fitness Equipment", label: "Fitness Equipment", tag: "shop" },
  { value: "General Services", label: "General Services", tag: "shop" },
  { value: "Health & Beauty", label: "Health & Beauty", tag: "shop" },
  { value: "Home Furnishing", label: "Home Furnishing", tag: "shop" },
  { value: "Hypermarket", label: "Hypermarket", tag: "shop" },
  { value: "Jewellery", label: "Jewellery", tag: "shop" },
  { value: "Kids Fashion", label: "Kids Fashion", tag: "shop" },
  {
    value: "Kids Playing Area & Drop-off",
    label: "Kids Playing Area & Drop-off",
    tag: "shop",
  },
  { value: "Lingerie & Swimwear", label: "Lingerie & Swimwear", tag: "shop" },
  { value: "Men's Fashion", label: "Men's Fashion", tag: "shop" },
  { value: "Shoes / Handbags", label: "Shoes / Handbags", tag: "shop" },
  {
    value: "Sporting Goods & Athletic Wear",
    label: "Sporting Goods & Athletic Wear",
    tag: "shop",
  },
  {
    value: "Stationery / Gifts / Cards / Books",
    label: "Stationery / Gifts / Cards / Books",
    tag: "shop",
  },
  { value: "Toys & Hobbies", label: "Toys & Hobbies", tag: "shop" },
  { value: "Watches", label: "Watches", tag: "shop" },
  { value: "Women's Fashion", label: "Women's Fashion", tag: "shop" },
];
