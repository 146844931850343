import { useMap } from "../../../context";
import CreatableSelect from "react-select/creatable";
import { CatOption, CatOptions, TagOptions } from "./data";
import Select, { OnChangeValue } from "react-select";
import { useState, useEffect } from "react";

// this component handle both the Tags and the categories selectors {first one for the tags, the second for the categories}

export default function ReactSelectComp(props: {
  tags: any;
  cats: any;
  setTags: any;
  setCats: any;
}) {
  const { selectedFeature } = useMap();

  // console.log(TagOptions.filter(tag => props.tags.includes(tag.value)));
  // console.log(CatOptions.filter(cat => props.cats.includes(cat.value)));
  const [chosenCats, setChosenCats] = useState(
    props.cats &&
      props.cats.map((cat: any) => {
        return {};
      })
  );

  //update the selected Cats every time it changes or the selected feature changes
  useEffect(() => {
    setChosenCats(
      props.cats &&
        props.cats.map((cat: any) => {
          return { value: cat, label: cat, tag: props.tags } || {};
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature, props.cats]);

  const handleCatChange = (newValue: OnChangeValue<CatOption, true>) => {
    // console.log(newValue);

    props.setCats(newValue.map((val) => val.value));

    // console.log(props.cats, chosenCats);
    // updateFeatureOnTheMap("categories", cats, + selectedFeature!.id);
    // console.log(`action: ${actionMeta.action}`);
  };

  const handleTagChange = (newValue: any) => {
    // console.log(newValue);

    props.setTags(newValue.value);

    // console.log(props.tags);
    // updateFeatureOnTheMap("tags", tags, + selectedFeature!.id);
    // console.log(`action: ${actionMeta.action}`);
  };

  return (
    <div className="selects-wrapper">
      <div className="tags-select-wrapper">
        <label>Tags </label>
        <div className="selector">
          <CreatableSelect /// default values
            value={{ label: props.tags, value: props.tags }}
            onChange={handleTagChange}
            options={(() => {
              let exists = false;
              TagOptions.forEach(
                (tag) => props.tags === tag.value && (exists = true)
              );

              if (!exists && props.tags !== "" && props.tags.length > 0) {
                return [
                  {
                    value: props.tags,
                    label: props.tags,
                  },
                  ...TagOptions,
                ];
              }

              return TagOptions;
            })()}
          />
        </div>
      </div>

      {(props.tags.includes("dine-in") || props.tags.includes("shop")) && (
        <div className="cats-select-wrapper">
          <label>Categories </label>
          <div className="selector">
            <CreatableSelect
              value={chosenCats}
              isMulti
              onChange={handleCatChange}
              options={CatOptions.filter((cat) =>
                props.tags ? (cat.tag === props.tags ? cat : null) : cat
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}
