import { useEffect, useMemo, useState } from "react";
import "./Drawer.css";
import { useMap } from "../../context";
// import NumberInput from "./InputComp/NumberInput";
import StringInput from "./InputComp/StringInput";
import DropDownInput from "./InputComp/DropDownInput";
import XYInput from "./InputComp/XYInput";
import ReactSelectComp from "./InputComp/ReactSelectComp";
import countryList from "react-select-country-list";
import Select from "react-select";
import EbagInput from "./InputComp/EbagInput/EbagInput";
import MediaInput from "./InputComp/MediaInput/MediaInput";
// import { CatOptions } from './InputComp/data';

/*
the Info form 
    - update the global state every time any of the values change
    - when you change the name, it update instantly on the map
    - update the data in the file when you click on Save button  
*/

export default function InfoEditor() {
  const {
    selectedFeature,
    updateFeatureOnTheMap,
    saveChanges,
    setSelectedFeatureExternalData,
    setSelectedFeatureProperty,
    setSelectedFeatureNewMedia,
  } = useMap();

  const [geofenceType, setGeofenceType] = useState(
    selectedFeature?.properties.geofenceType || "other"
  ); //"door" | "stair" | "elevator" | "other"
  const [gender, setGender] = useState(
    selectedFeature?.properties.gender || "all"
  ); //"male" | "female"
  const [name, setName] = useState(
    selectedFeature?.properties.name || "Temp Name"
  );
  const [description, setDescription] = useState(
    selectedFeature?.externalData?.description || ""
  );

  const [arabicDescription, setArabicDescription] = useState(
    selectedFeature?.externalData?.arabicDescription || ""
  );
  const [country, setCountry] = useState(
    selectedFeature?.externalData?.country || ""
  );

  const countryOptions = useMemo(() => countryList().getData(), []);

  const [phone, setPhone] = useState(selectedFeature?.externalData?.phone);
  const [website, setWebsite] = useState(
    selectedFeature?.externalData?.website || ""
  );
  const [workingHours, setWorkingHours] = useState(
    selectedFeature?.externalData?.workingHours || ""
  );
  const [app, setApp] = useState(selectedFeature?.externalData?.app || "");
  const [stair, setStair] = useState({
    from: selectedFeature?.properties?.stair?.from || 0,
    to: selectedFeature?.properties?.stair?.to || 0,
  });
  const [cats, setCats] = useState(
    selectedFeature?.externalData?.categories || ""
  );
  const [tags, setTags] = useState(selectedFeature?.externalData?.tags || "");
  const [ebag, setEbag] = useState(selectedFeature?.externalData?.ebag || []);
  const [images, setImages] = useState(
    selectedFeature?.externalData?.images || []
  );
  const [videos, setVideos] = useState(
    selectedFeature?.externalData?.videos || []
  );

  const [externalData, setExternalData] = useState({
    tags: tags,
    categories: cats,
    description: description,
    arabicDescription: arabicDescription,
    country: country,
    phone: phone,
    rate: selectedFeature?.externalData?.rate,
    website: website,
    workingHours: workingHours,
    album: selectedFeature?.externalData?.album,
    app: app,
    ebag: ebag,
    images: images,
    videos: videos,
  });

  useEffect(() => {
    setExternalData({
      tags: tags,
      categories: cats,
      description: description,
      arabicDescription: arabicDescription,
      country: country,
      phone: phone,
      rate: selectedFeature?.externalData?.rate,
      website: website,
      workingHours: workingHours,
      album: selectedFeature?.externalData?.album,
      app: app,
      ebag: ebag,
      images: images,
      videos: videos,
    });
    // console.log(externalData);
  }, [
    selectedFeature,
    app,
    cats,
    description,
    arabicDescription,
    country,
    phone,
    tags,
    website,
    workingHours,
    ebag,
    images,
    videos,
  ]);

  useEffect(() => {
    setGeofenceType(selectedFeature?.properties.geofenceType || "other");
    setGender(selectedFeature?.properties.gender || "all"); //"male" | "female"
    setName(selectedFeature?.properties.name || "Temp Name");
    setDescription(selectedFeature?.externalData?.description || "");
    setArabicDescription(
      selectedFeature?.externalData?.arabicDescription || ""
    );
    setCountry(selectedFeature?.externalData?.country || "");
    setPhone(selectedFeature?.externalData?.phone || "");
    setWebsite(selectedFeature?.externalData?.website || "");
    setWorkingHours(selectedFeature?.externalData?.workingHours || "");
    setApp(selectedFeature?.externalData?.app || "");
    setStair({
      from: selectedFeature?.properties?.stair?.from || 0,
      to: selectedFeature?.properties?.stair?.to || 0,
    });
    setCats(selectedFeature?.externalData?.categories || "");
    setTags(selectedFeature?.externalData?.tags || "");
    setEbag(selectedFeature?.externalData?.ebag || []);
    setImages(selectedFeature?.externalData?.images || []);
    setVideos(selectedFeature?.externalData?.videos || []);
    setExternalData({
      tags: tags,
      categories: cats,
      description: description,
      arabicDescription: arabicDescription,
      country: country,
      phone: phone,
      rate: selectedFeature?.externalData?.rate,
      website: website,
      workingHours: workingHours,
      album: selectedFeature?.externalData?.album,
      app: app,
      ebag: ebag,
      images: images,
      videos: videos,
    });
    // console.log(externalData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);
  // console.log(selectedFeature);

  const handleXYChange = (X: any, Y: any) => {
    setStair({
      from: X,
      to: Y,
    });
    //updateFeatureOnTheMap(property, value, + selectedFeature!.id);
  };

  return (
    <div className="info-container">
      <div className="info">
        {!selectedFeature && (
          <div className="geometry-not-selected">Select Geometry First</div>
        )}

        <div className="info-editor-form-container">
          {selectedFeature && (
            <div className="info-editor-form">
              <div className="input-element1">
                <StringInput
                  value={name}
                  property={"name"}
                  label={"Name"}
                  setvalue={(value: any, property: any) => {
                    setName(value);
                    setSelectedFeatureProperty("name", value);
                    updateFeatureOnTheMap(
                      property,
                      value,
                      +selectedFeature!.id
                    );
                  }}
                />
              </div>

              {/* Tags - categories */}
              <div className="input-element1">
                <ReactSelectComp
                  tags={tags}
                  setTags={(value: any) => {
                    setTags(value);
                    setSelectedFeatureExternalData({
                      ...externalData,
                      tags: value,
                    });
                  }}
                  cats={cats}
                  setCats={(value: any) => {
                    setCats(value);
                    setSelectedFeatureExternalData({
                      ...externalData,
                      categories: value,
                    });
                  }}
                />
              </div>

              <div className="input-element1 country-select">
                <label htmlFor="">Country</label>
                <Select
                  value={{ label: country, value: country }}
                  options={countryOptions}
                  onChange={(value: any) => {
                    setCountry(value.value);
                    setSelectedFeatureExternalData({
                      ...externalData,
                      country: value.value,
                    });
                  }}
                />
              </div>

              <div className="input-element1">
                <XYInput
                  value={{ x: stair.from, y: stair.to }}
                  property={"stair"}
                  setvalue={(X, Y) => {
                    handleXYChange(X, Y);
                    setSelectedFeatureProperty("stair", { from: X, to: Y });
                  }}
                />
              </div>

              <div className="input-element">
                <DropDownInput
                  value={geofenceType}
                  options={["other", "door", "stair", "elevator"]}
                  property={"geofenceType"}
                  label={"Geofence Type"}
                  setvalue={(value: any, property: any) => {
                    setGeofenceType(value);
                    setSelectedFeatureProperty("geofenceType", value);
                  }}
                />
                <DropDownInput
                  value={gender}
                  options={["all", "male", "female"]}
                  property={"gender"}
                  label={"Gender"}
                  setvalue={(value: any, property: any) => {
                    setGender(value);
                    setSelectedFeatureProperty("gender", value);
                  }}
                />
              </div>

              <div className="input-element1">
                <StringInput
                  value={description}
                  property={"description"}
                  label={"Description"}
                  setvalue={(value: any, property: any) => {
                    setDescription(value);
                    setSelectedFeatureExternalData({
                      ...externalData,
                      [property]: value,
                    });
                  }}
                />
              </div>

              <div className="input-element1">
                <StringInput
                  value={arabicDescription}
                  property={"description"}
                  label={"الوصف"}
                  setvalue={(value: any, property: any) => {
                    setArabicDescription(value);
                    setSelectedFeatureExternalData({
                      ...externalData,
                      arabicDescription: value,
                    });
                  }}
                />
              </div>

              <div className="input-element1">
                <StringInput
                  value={phone}
                  property={"phone"}
                  label={"Phone"}
                  setvalue={(value: any, property: any) => {
                    setPhone(value);
                    console.log(value);
                    setSelectedFeatureExternalData({
                      ...externalData,
                      [property]: value,
                    });
                  }}
                />
              </div>

              <div className="input-element1">
                <StringInput
                  value={website}
                  property={"website"}
                  label={"Website"}
                  setvalue={(value: any, property: any) => {
                    setWebsite(value);
                    setSelectedFeatureExternalData({
                      ...externalData,
                      [property]: value,
                    });
                  }}
                />
              </div>

              <div className="input-element1">
                <StringInput
                  value={workingHours}
                  property={"workingHours"}
                  label={"Working Hours"}
                  setvalue={(value: any, property: any) => {
                    setWorkingHours(value);
                    setSelectedFeatureExternalData({
                      ...externalData,
                      [property]: value,
                    });
                  }}
                />
              </div>

              {/* album */}

              <div className="input-element1">
                <StringInput
                  value={app}
                  property={"app"}
                  label={"App"}
                  setvalue={(value: any, property: any) => {
                    setApp(value);
                    setSelectedFeatureExternalData({
                      ...externalData,
                      [property]: value,
                    });
                  }}
                />
              </div>

              <EbagInput
                value={selectedFeature?.externalData?.ebag || []}
                onChange={(newEbag) => {
                  setEbag(newEbag);
                  setSelectedFeatureExternalData({
                    ...externalData,
                    ebag: newEbag,
                  });
                }}
              />

              <MediaInput
                media={[
                  ...(selectedFeature?.externalData?.images?.map((url) => ({
                    type: "image" as const,
                    url: url,
                  })) || []),
                  ...(selectedFeature?.externalData?.videos?.map((url) => ({
                    type: "video" as const,
                    url: url,
                  })) || []),
                ]}
                onChange={(newImages) => {
                  const newImagesUrls = newImages
                    .filter((media) => media.type === "image")
                    .map((media) => media.url);
                  const newVideosUrls = newImages
                    .filter((media) => media.type === "video")
                    .map((media) => media.url);
                  setImages(newImagesUrls);
                  setVideos(newVideosUrls);
                  setSelectedFeatureExternalData({
                    ...externalData,
                    images: newImagesUrls,
                    videos: newVideosUrls,
                  });
                }}
                newMedia={selectedFeature?.newMedia}
                onNewMediaChange={(newMedia) => {
                  setSelectedFeatureNewMedia(newMedia);
                }}
              />

              <div className="style-bottom-container">
                <button className="save-button" onClick={saveChanges}>
                  {" "}
                  Save{" "}
                </button>
                <button className="cancel-button"> Cancel </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
