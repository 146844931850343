// import React, { useState } from "react";
import "../Drawer.css";


// input Component for the satirs which take to numbers  

export default function XYInput(props: {
  value: {x: number, y: number};
  property: string;
  setvalue: (a: any, b:any) => void;
}) {

    return <div className="number-XYinput-wrapper">
        <label>{props.property} </label>
        <div className="number-input">
            <div className="number-input-container">
                <div className="number-input-sublabel">{props.property === "stair" ? "From" : Object.keys(props.value)[0]}</div>

                <div className="input-counter">
                    <input type="text" value={Object.values(props.value)[0]} onChange={(e) => 
                        {props.setvalue(parseInt(e.target.value === "" ? "0" : e.target.value), Object.values(props.value)[1]); console.log(props.value)}} />
                </div>

                <div className="counter-containers">
                    <div className="Count-up" onClick={()=>props.setvalue(Object.values(props.value)[0]+1, Object.values(props.value)[1])}>
                        ▲
                    </div>
                    <div className="Count-down" onClick={()=>
                        props.setvalue(Object.values(props.value)[0]>0?Object.values(props.value)[0]-1:0, Object.values(props.value)[1])}>
                        ▼
                    </div>
                </div>
            </div>

            <div className="number-input-container">
                <div className="number-input-sublabel">{props.property === "stair" ? "To" : Object.keys(props.value)[1]}</div>

                <div className="input-counter">
                    <input type="text" value={ Object.values(props.value)[1]} onChange={(e) =>
                         props.setvalue(Object.values(props.value)[0], parseInt(e.target.value === "" ? "0" : e.target.value))} />
                </div>

                <div className="counter-containers">
                    <div className="Count-up" onClick={()=>props.setvalue(Object.values(props.value)[0], Object.values(props.value)[1]+1)}>
                        ▲
                    </div>
                    <div className="Count-down" onClick={()=>
                        props.setvalue(Object.values(props.value)[0], Object.values(props.value)[1]>0?Object.values(props.value)[1]-1:0)}>
                        ▼
                    </div>
                </div>
            </div>
        </div>
    </div>
}