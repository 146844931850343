// import { type } from "os";
import { useState, useEffect } from "react";
import { useMap } from "../../context";
import "./Drawer.css";
import NumberInput from "./InputComp/NumberInput";
import StringInput from "./InputComp/StringInput";
import DropDownInput from "./InputComp/DropDownInput";
import ColorPicker from "../ColorPicker"; // Added ColorPicker component

/*
the Styling form 
    - show the Icon editing options if the isIcon flag is set to true other wise show the name editing options
    - update the global state every time any of the values change
    - show the update on the map
    - update the data in the file when you click on Save button  
*/
export default function StyleEditor() {
  const {
    selectedFeature,
    updateFeatureOnTheMap,
    saveChanges,
    setSelectedFeatureProperty,
  } = useMap();
  //const [style, setStyle] = useState<any>(selectedFeature?.properties?.style);
  const [style, setStyle] = useState<any>({
    depth: selectedFeature?.properties.style?.depth || 100, // percentage Number, default is 100
    fontSize: selectedFeature?.properties.style?.fontSize || 12,
    fontFamily: selectedFeature?.properties.style?.fontFamily || "sans-serif",
    letterSpacing: selectedFeature?.properties.style?.letterSpacing || 0,
    fontStyle: selectedFeature?.properties.style?.fontStyle || "normal", //"normal" | "italic" | "oblique";
    fontWeight: selectedFeature?.properties.style?.fontWeight || "normal", //"normal" | "bold";
    bgColor: selectedFeature?.properties.style?.bgColor || "#373737",
    color: selectedFeature?.properties.style?.color || "yellow",
    rotate: selectedFeature?.properties.style?.rotate || 0,
    icon: selectedFeature?.properties.style?.icon || "",
    iconHeight: selectedFeature?.properties.style?.iconHeight || 20,
    iconWidth: selectedFeature?.properties.style?.iconWidth || 20,
    isIcon: selectedFeature?.properties.style?.isIcon || false,
    textPosition: selectedFeature?.properties.style?.textPosition || [0, 0],
    imgPosition: selectedFeature?.properties.style?.imgPosition || [0, 0],
  });

  useEffect(() => {
    //setStyle(selectedFeature?.properties?.style);
    setStyle({
      depth: selectedFeature?.properties.style?.depth || 100, // percentage Number, default is 100
      fontSize: selectedFeature?.properties.style?.fontSize || 12,
      fontFamily: selectedFeature?.properties.style?.fontFamily || "sans-serif",
      letterSpacing: selectedFeature?.properties.style?.letterSpacing || 0,
      fontStyle: selectedFeature?.properties.style?.fontStyle || "normal", //"normal" | "italic" | "oblique";
      fontWeight: selectedFeature?.properties.style?.fontWeight || "normal", //"normal" | "bold";
      bgColor: selectedFeature?.properties.style?.bgColor || "#373737",
      color: selectedFeature?.properties.style?.color || "yellow",
      rotate: selectedFeature?.properties.style?.rotate || 0,
      icon: selectedFeature?.properties.style?.icon || "",
      iconHeight: selectedFeature?.properties.style?.iconHeight || 0,
      iconWidth: selectedFeature?.properties.style?.iconWidth || 0,
      isIcon: selectedFeature?.properties.style?.isIcon || false,
      textPosition: selectedFeature?.properties.style?.textPosition || [0, 0],
      imgPosition: selectedFeature?.properties.style?.imgPosition || [0, 0],
    });
  }, [selectedFeature]);

  const handleChange = (value: any, property: any) => {
    setStyle({ ...style, [property]: value });
    setSelectedFeatureProperty("style", { ...style, [property]: value });
    updateFeatureOnTheMap(property, value, +selectedFeature!.id);
    console.log(property, value);
  };

  return (
    <div className="style">
      <div className="style-container">
        {!selectedFeature && (
          <div className="geometry-not-selected">Select Geometry First</div>
        )}

        <div className="style-editor-form-container">
          {selectedFeature && (
            <div className="style-editor-form">
              <div
                className="show-name-icon-wrapper"
                style={{ marginTop: "30px" }}
              >
                <div
                  className="show-name-icon"
                  onClick={() => {
                    setStyle({ ...style, isIcon: !style.isIcon });
                    setSelectedFeatureProperty("style", {
                      ...style,
                      isIcon: !style.isIcon,
                    });
                    updateFeatureOnTheMap(
                      "isIcon",
                      !style.isIcon,
                      +selectedFeature!.id
                    );
                    !style.isIcon &&
                      updateFeatureOnTheMap(
                        "icon",
                        style.icon,
                        +selectedFeature!.id
                      );
                  }}
                >
                  {style.isIcon ? "Show Name" : "Show Icon"}
                </div>
              </div>

              <div className="input-element">
                <StringInput
                  value={style.bgColor}
                  label={"Layer Color"}
                  property={"bgColor"}
                  setvalue={handleChange}
                />

                <NumberInput
                  value={style.depth}
                  label={"Depth"}
                  property={"depth"}
                  setvalue={handleChange}
                />
              </div>

              {!style.isIcon && (
                <div className="input-element1">
                  <DropDownInput
                    value={style.fontFamily}
                    options={[
                      "sans-serif",
                      "Aldrich",
                      "Arima Madurai",
                      "Arvo",
                      "Henny Penny",
                      "Indie Flower",
                      "Libre Baskerville",
                      "Pirata One",
                      "Poiret One",
                      "Sancreek",
                      "Satisfy",
                      "Share Tech Mono",
                      "Smokum",
                    ]}
                    label={"Font Family"}
                    property={"fontFamily"}
                    setvalue={handleChange}
                  />
                </div>
              )}

              {!style.isIcon && (
                <div className="input-element1">
                  <NumberInput
                    value={style.letterSpacing}
                    label={"Letter Spacing"}
                    property={"letterSpacing"}
                    setvalue={handleChange}
                  />
                </div>
              )}

              {!style.isIcon && (
                <div className="input-element">
                  <DropDownInput
                    value={style.fontStyle}
                    options={["normal", "italic", "oblique"]}
                    property={"fontStyle"}
                    label={"Font Style"}
                    setvalue={handleChange}
                  />
                  <DropDownInput
                    value={style.fontWeight}
                    options={["normal", "bold"]}
                    property={"fontWeight"}
                    label={"Font Weight"}
                    setvalue={handleChange}
                  />
                </div>
              )}

              {!style.isIcon && (
                <div className="input-element">
                  <StringInput
                    value={style.color}
                    property={"color"}
                    label={"Text Color"}
                    setvalue={handleChange}
                  />

                  <NumberInput
                    value={
                      typeof style.fontSize === "number"
                        ? style.fontSize
                        : parseInt(style.fontSize)
                    }
                    label={"Font Size"}
                    property={"fontSize"}
                    setvalue={handleChange}
                  />
                </div>
              )}

              <div className="input-element1">
                <NumberInput
                  value={style.rotate}
                  label={"Rotate"}
                  property={"rotate"}
                  setvalue={handleChange}
                />
              </div>

              {/* Margin Controller */}
              <div className="input-element1">
                <div className="margin-Container">
                  {/* <div className="label"> Name Position </div> */}
                  <div className="margin-wrapper">
                    <div
                      className="upper margin-item"
                      onClick={() => {
                        !style.isIcon
                          ? updateFeatureOnTheMap(
                              "textPosition",
                              "U",
                              +selectedFeature!.id
                            )
                          : updateFeatureOnTheMap(
                              "imgPosition",
                              "U",
                              +selectedFeature!.id
                            );

                        !style.isIcon
                          ? setSelectedFeatureProperty("style", {
                              ...style,
                              textPosition: [
                                style.textPosition[0],
                                style.textPosition[1] + 5 / 377.95275591,
                              ],
                            })
                          : setSelectedFeatureProperty("style", {
                              ...style,
                              imgPosition: [
                                style.imgPosition[0],
                                style.imgPosition[1] + 5 / 377.95275591,
                              ],
                            });

                        !style.isIcon
                          ? setStyle({
                              ...style,
                              textPosition: [
                                style.textPosition[0],
                                style.textPosition[1] + 5 / 377.95275591,
                              ],
                            })
                          : setStyle({
                              ...style,
                              imgPosition: [
                                style.imgPosition[0],
                                style.imgPosition[1] + 5 / 377.95275591,
                              ],
                            });
                        // console.log(style.textPosition);
                      }}
                    >
                      U
                    </div>

                    <div className="middle">
                      <div
                        className="left margin-item"
                        onClick={() => {
                          !style.isIcon
                            ? updateFeatureOnTheMap(
                                "textPosition",
                                "L",
                                +selectedFeature!.id
                              )
                            : updateFeatureOnTheMap(
                                "imgPosition",
                                "L",
                                +selectedFeature!.id
                              );

                          !style.isIcon
                            ? setSelectedFeatureProperty("style", {
                                ...style,
                                textPosition: [
                                  style.textPosition[0] - 5 / 377.95275591,
                                  style.textPosition[1],
                                ],
                              })
                            : setSelectedFeatureProperty("style", {
                                ...style,
                                imgPosition: [
                                  style.imgPosition[0] - 5 / 377.95275591,
                                  style.imgPosition[1],
                                ],
                              });

                          !style.isIcon
                            ? setStyle({
                                ...style,
                                textPosition: [
                                  style.textPosition[0] - 5 / 377.95275591,
                                  style.textPosition[1],
                                ],
                              })
                            : setStyle({
                                ...style,
                                imgPosition: [
                                  style.imgPosition[0] - 5 / 377.95275591,
                                  style.imgPosition[1],
                                ],
                              });
                        }}
                      >
                        L
                      </div>

                      {style.isIcon ? (
                        <div className="label"> Image Position </div>
                      ) : (
                        <div className="label"> Text Position </div>
                      )}
                      <div
                        className="right margin-item"
                        onClick={() => {
                          !style.isIcon
                            ? updateFeatureOnTheMap(
                                "textPosition",
                                "R",
                                +selectedFeature!.id
                              )
                            : updateFeatureOnTheMap(
                                "imgPosition",
                                "R",
                                +selectedFeature!.id
                              );

                          !style.isIcon
                            ? setSelectedFeatureProperty("style", {
                                ...style,
                                textPosition: [
                                  style.textPosition[0] + 5 / 377.95275591,
                                  style.textPosition[1],
                                ],
                              })
                            : setSelectedFeatureProperty("style", {
                                ...style,
                                imgPosition: [
                                  style.imgPosition[0] + 5 / 377.95275591,
                                  style.imgPosition[1],
                                ],
                              });

                          !style.isIcon
                            ? setStyle({
                                ...style,
                                textPosition: [
                                  style.textPosition[0] + 5 / 377.95275591,
                                  style.textPosition[1],
                                ],
                              })
                            : setStyle({
                                ...style,
                                imgPosition: [
                                  style.imgPosition[0] + 5 / 377.95275591,
                                  style.imgPosition[1],
                                ],
                              });
                        }}
                      >
                        R
                      </div>
                    </div>

                    <div
                      className="lower margin-item"
                      onClick={() => {
                        !style.isIcon
                          ? updateFeatureOnTheMap(
                              "textPosition",
                              "D",
                              +selectedFeature!.id
                            )
                          : updateFeatureOnTheMap(
                              "imgPosition",
                              "D",
                              +selectedFeature!.id
                            );

                        !style.isIcon
                          ? setSelectedFeatureProperty("style", {
                              ...style,
                              textPosition: [
                                style.textPosition[0],
                                style.textPosition[1] - 5 / 377.95275591,
                              ],
                            })
                          : setSelectedFeatureProperty("style", {
                              ...style,
                              imgPosition: [
                                style.imgPosition[0],
                                style.imgPosition[1] - 5 / 377.95275591,
                              ],
                            });

                        !style.isIcon
                          ? setStyle({
                              ...style,
                              textPosition: [
                                style.textPosition[0],
                                style.textPosition[1] - 5 / 377.95275591,
                              ],
                            })
                          : setStyle({
                              ...style,
                              imgPosition: [
                                style.imgPosition[0],
                                style.imgPosition[1] - 5 / 377.95275591,
                              ],
                            });
                      }}
                    >
                      D
                    </div>
                  </div>
                </div>
              </div>

              {style.isIcon && (
                <div className="input-element1">
                  <StringInput
                    value={style.icon}
                    property={"icon"}
                    label={"Icon URL"}
                    setvalue={handleChange}
                  />
                </div>
              )}

              {style.isIcon && (
                <div className="input-element">
                  <NumberInput
                    value={style.iconHeight}
                    label={"Icon Height"}
                    property={"iconHeight"}
                    setvalue={handleChange}
                  />
                  <NumberInput
                    value={style.iconWidth}
                    label={"Icon Width"}
                    property={"iconWidth"}
                    setvalue={handleChange}
                  />
                </div>
              )}
            <ColorPicker
                value={style.bgColor}
                label={"Layer Color"}
                property={"bgColor"}
                setvalue={handleChange}
              />
              <div className="style-bottom-container">
                <button className="save-button" onClick={saveChanges}>
                  {" "}
                  Save{" "}
                </button>
                <button className="cancel-button"> Cancel </button>
              </div>
            </div>
          )}
        </div>
      
      </div>
    </div>
  );
}
