import "../Drawer.css";

// a component for the String inputs 
/*
    value: the value in the field
    property: the property name
    label: the label for Input
    setvalue: function that sets the field to the new value

    if its color or background color the input type is color other wise it's text 
    and it's textarea for the description
*/

export default function NumberInput(props: {
  value: string | undefined;
  property: string;
  label: string;
  setvalue: (a: any, b:any) => void;
}) {

    return <div className="text-input-wrapper">
        <label>{props.label} </label>
        <div className="text-input">
            <div className="text-input-container">
                <div className="input-text">
                    {(props.property !== "color" && props.property !== "bgColor" && props.property !== "description") && 
                        <input type="text"  style={{width: '100%', height: "40px", resize: "none"}} 
                        value={props.value} onChange={(e) => props.setvalue(e.target.value, props.property)} placeholder={props.label}/>}

                    {(props.property === "description") && <textarea id="description" name="description" style={{width: '100%', height: "70px", resize: "none"}}
                        value={props.value} onChange={(e) => props.setvalue(e.target.value, props.property)} placeholder={props.label}/>}    

                    {(props.property === "color" || props.property === "bgColor") &&
                        <input type="color" id="color-picker" name="color-picker" value={props.value} placeholder={props.label}
                        onChange={(e) => props.setvalue(e.target.value, props.property)} />}
                </div>
            </div>
        </div>
    </div>
}