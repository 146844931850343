import "../Drawer.css";

// a component for the number inputs 
/*
    value: the value in the field
    property: the property name
    label: the label for Input
    setvalue: function that sets the field to the new value
*/

export default function NumberInput(props: {
  value: number;
  property: string;
  label: string;
  setvalue: (a: any, b:any) => void;
}) {

    /*
        this component is devided into 2 parts: 
        - the first one handles all the number inputs except the rotation {
            you can change the number directly by writing the new number or 
            clicking the up and the down arrows to increase or decrease the vlaue
            * it accepts only numbers
        }
        - the second one handles the rotate inputs {
            by sliding the circle on the bare you will change the value of the rotation
        }
    */

    return <div className="number-input-wrapper">
        <label>{props.label} </label>
        <div className="number-input">
            {
                props.property !== "rotate" && <div className="number-input-container"> 
                    <div className="input-counter"> 
                        <input type="text" value={props.value} onChange={(e) => 
                            props.setvalue(parseInt(e.target.value === "" ? "0" : e.target.value), props.property)} />
                    </div>
                    <div className="counter-containers">
                        <div className="Count-up" onClick={()=>props.setvalue(props.value+1, props.property)}>
                            ▲
                        </div>
                        <div className="Count-down" onClick={()=>props.setvalue(props.value > 0 ? props.value-1 : 0, props.property)}>
                            ▼
                        </div>
                    </div>
                </div>
            }
            
            {props.property === "rotate" && <div className="slider-input-container">
                <input type="range" min="-180" max="180" value={props.value.toString()} className="rotate-slider"
                    onChange={(e) => props.setvalue(parseInt(e.target.value === "" ? "0" : e.target.value), props.property)} />
            </div>}

        </div>
    </div>
}

