import React from "react";
import { Routes, Route } from "react-router-dom";
import { Drawer } from "./components/mapEditor/Drawer";
import Nav from "./components/Nav";
import RemoteComponent from "./components/RemoteComponent";
import { useMap } from "./context";

export default function App() {
  const { drawerStatus, toggleDrawerStatus } = useMap();

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      {/* <Backdrop show={drawerStatus} onClick={toggleDrawerStatus} /> */}
      <Drawer isOpen={drawerStatus} onClick={toggleDrawerStatus} />
      <Nav />
      <RemoteComponent name="mapEditor" scope="indoor" bounds={[]} />
      <Routes>
        <Route path="/" element={<></>} />
      </Routes>
    </div>
  );
}
