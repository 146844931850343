import React from "react";

import IndoorComponent from "./IndoorMap";

const useDynamicScript = (url) => {
  const [ready, setReady] = React.useState(false);
  const [failed, setFailed] = React.useState(false);

  React.useEffect(() => {
    if (!url) {
      return;
    }

    const element = document.createElement("script");
    element.src = url;
    element.type = "text/javascript";
    element.async = true;

    setReady(false);
    setFailed(false);

    element.onload = () => {
      console.log(`Dynamic Script Loaded: ${url}`);
      setReady(true);
    };

    element.onerror = () => {
      console.error(`Dynamic Script Error: ${url}`);
      setReady(false);
      setFailed(true);
    };

    document.head.appendChild(element);

    return () => {
      console.log(`Dynamic Script Removed: ${url}`);
      document.head.removeChild(element);
    };
  }, [url]);

  return {
    ready,
    failed,
  };
};

const RemoteReactComponent = ({ url, scope, module, ...props }) => {
  const { ready, failed } = useDynamicScript(url);
  const [loading, setLoading] = React.useState(true);
  const moduleRef = React.useRef(false);

  const getModule = React.useCallback(async () => {
    try {
      moduleRef.current = await window[scope].get(module).then((factory) => {
        const Module = factory();
        // console.log({ Module }, Module.mount);
        return Module;
      });
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }, [module, scope]);

  React.useEffect(() => {
    if (!ready) return;
    getModule();
  }, [ready, getModule]);

  if (failed) {
    return <h2>Failed to load the application</h2>;
  }

  if (!loading)
    return <IndoorComponent module={moduleRef.current} {...props} />;

  return <h4>Loading application ...</h4>;
};

function RemoteComponent(props) {
  return (
    <RemoteReactComponent
      url={`${
        process.env.REACT_APP_INDOOR_API || "http://localhost:1594"
      }/remoteEntry.js`}
      module={props.name}
      scope={props.scope || props.name}
      {...props}
    />
  );
}

export default RemoteComponent;
