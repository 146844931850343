import "./EbagInput.css"

export type Ebag = {
  name: string;
  url: string;
}[];

type EbagInputProps = {
  value: Ebag;
  onChange: (value: Ebag) => void;
};

const EbagInput = ({ value: ebag, onChange }: EbagInputProps) => {
  return (
    <form className="ebagForm input-element1">
      <label>Ebag</label>
      {ebag.map((item, i) => {
        return (
          <div key={i} className="ebagItem">
            <input
              placeholder="Name"
              type="text"
              value={item.name}
              onChange={(e) => {
                const newValue = [...ebag];
                newValue[i].name = e.target.value;
                onChange(newValue);
              }}
            />
            <input
              placeholder="URL"
              type="text"
              value={item.url}
              onChange={(e) => {
                const newValue = [...ebag];
                newValue[i].url = e.target.value;
                onChange(newValue);
              }}
            />
            <button
              className="delete-button"
              type="button"
              onClick={() => {
                const newValue = [...ebag];
                newValue.splice(i, 1);
                onChange(newValue);
              }}
            >
              Delete
            </button>
          </div>
        );
      })}

      <button
        type="button"
        className="add-button"
        onClick={() => {
          onChange([...ebag, { name: "", url: "" }]);
        }}
      >
        Add
      </button>
    </form>
  );
};

export default EbagInput;
