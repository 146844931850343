import "../Drawer.css";

// a component for the dropdown inputs 
/*
    value: the value of the choosen option
    property: the property name
    label: the label for dropdown
    options: the options for the dropdown
    setvalue: function that sets the chosen option
*/

export default function DropDownInput(props: {
  value: string;
  property: string;
  label: string;
  options: string[];
  setvalue: (a: any, b:any) => void;
}) {

    return <div className="dropdown-input-wrapper">
        <label>{props.label} </label>
        <div className="dropdown-input">
            <div className="dropdown-input-container">
                <div className="input-dropdown">
                    <select onChange={(e) => props.setvalue(e.target.value, props.property)}>
                        {props.options.map((item, i)=> {
                            return <option style={{fontFamily: props.property === "fontFamily" ? item : ""}} key={i} value={item}>{item}</option>
                        })}
                    </select>
                </div>
            </div>
        </div>
    </div>
}
