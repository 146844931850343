// ColorPicker.tsx
import React, { ChangeEvent } from "react";

interface ColorPickerProps {
  value: string;
  label: string;
  property: string;
  setvalue: (value: string, property: string) => void;
}

export default function ColorPicker({
  value,
  label,
  property,
  setvalue,
}: ColorPickerProps) {
  const handleColorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setvalue(e.target.value, property);
  };

  return (
<div className="color-picker">
      <label>{label}</label>
      <select value={value} onChange={handleColorChange}>
        <option value="#355061" style={{ backgroundColor: "#355061", color: "white" }}>
          Dark Blue
        </option>
        <option value="#e1ddde" style={{ backgroundColor: "#e1ddde" }}>
          Light Grayish
        </option>
        <option value="#ffffff" style={{ backgroundColor: "#ffffff" }}>
          White
        </option>
        <option value="#404040" style={{ backgroundColor: "#404040", color: "white" }}>
          Charcoal Gray
        </option>
        <option value="#6f3973" style={{ backgroundColor: "#6f3973", color: "white" }}>
          Royal Purple
        </option>
        <option value="#fefefe" style={{ backgroundColor: "#fefefe" }}>
          Snow White
        </option>
        <option value="#526d78" style={{ backgroundColor: "#526d78", color: "white" }}>
          Steel Blue
        </option>
      </select>
    </div>
  );
}
