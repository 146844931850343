import { features } from "process";
import { feature, State } from "./types";

type Action =
  | { type: "PENDING" }
  | { type: "UPDATED_SUCCESSFULLY"; payload: feature }
  | { type: "FAIL"; payload: unknown }
  | { type: "TOGGLE_DRAWER_STATUS" }
  | { type: "SET_MAP_MODULE"; payload: State["mapModule"] }
  | { type: "SET_SEARCH_INPUT"; payload: string }
  | { type: "SET_SELECTED_LAYER"; payload: string }
  | { type: "SET_SELECTED_FEATURE"; payload: feature["id"] }
  | { type: "SET_FEATURE_PROPERTY"; payload: { value: any; id: string } }
  | { type: "SET_FEATURE_EXTERNAL_DATA"; payload: { value: any } }
  | { type: "SET_FEATURES"; payload: State["features"] }
  | { type: "SET_FEATURE_NEW_MEDIA"; payload: { value: feature["newMedia"] } };

const Reducer = (state: State, action: Action): State => {
  try {
    switch (action.type) {
      case "PENDING":
        return {
          ...state,
          isLoading: true,
          error: false,
        };

      case "TOGGLE_DRAWER_STATUS":
        return {
          ...state,
          drawerStatus: !state.drawerStatus,
        };

      case "SET_SEARCH_INPUT":
        return {
          ...state,
          searchInput: action.payload,
          selectedLayer: !action.payload ? "" : state.selectedLayer,
        };

      case "SET_SELECTED_LAYER":
        return {
          ...state,
          selectedLayer: action.payload,
        };

      case "SET_SELECTED_FEATURE":
        const selectedFeature = state.features?.layers?.find(
          (f) => f.id === action.payload
        );
        return {
          ...state,
          selectedFeature: selectedFeature!,
        };

      case "SET_FEATURE_PROPERTY":
        state.features?.layers?.forEach((feature: feature) => {
          if (feature.id === action.payload.id!)
            feature.properties = action.payload.value;
        });
        console.log(
          "state.features: ",
          state.features!.layers!.filter(
            (feature) => feature.id === action.payload.id!
          )
        );

        return {
          ...state,
          selectedFeature: {
            ...state.selectedFeature!,
            properties: action.payload.value,
          },
        };

      case "SET_FEATURE_EXTERNAL_DATA":
        return {
          ...state,
          selectedFeature: {
            ...state.selectedFeature!,
            externalData: action.payload.value,
          },
        };

      case "SET_FEATURE_NEW_MEDIA":
        return {
          ...state,
          selectedFeature: {
            ...state.selectedFeature!,
            newMedia: action.payload.value,
          },
        };

      case "FAIL":
        console.error("Error: ", action.payload);
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      case "SET_FEATURES":
        return {
          ...state,
          features: action.payload,
        };

      case "SET_MAP_MODULE":
        return {
          ...state,
          mapModule: action.payload,
        };

      case "UPDATED_SUCCESSFULLY":
        return {
          ...state,
          isLoading: false,
          error: false,
          features: {
            ...state.features!,
            layers: state.features!.layers!.map((feature: feature) =>
              feature.id === action.payload.id ? action.payload : feature
            ),
          },
          drawerStatus: !state.drawerStatus,
        };

      default:
        return state;
    }
  } catch (err) {
    console.error(err);
    return state;
  }
};

export default Reducer;
