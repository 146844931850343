import React, { useState } from "react";
// import { useMap } from "../../context";
import InfoEditor from "./InfoEditor";
import StyleEditor from "./StyleEditor";
import "./Drawer.css";
type BackdropTypes = {
  show: boolean;
  onClick: () => void;
};

type drawerProps = {
  show?: boolean;
  direction?: "right" | "left" | "top" | "bottom";
  // children: React.ReactNode;
  isOpen: boolean;
  speed?: number;
  width?: string;
  height?: string;
  bgColor?: string;
  zIndex?: number;
  className?: string;
  style?: any;
  onClick: () => void;
};

export const Backdrop = (props: BackdropTypes) =>
  props.show ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        zIndex: 90,
        left: 0,
        top: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
      onClick={props.onClick}
    ></div>
  ) : null;

export const directions = (isOpen: boolean) => ({
  right: {
    transform: isOpen ? "translateX(-100%)" : "translateX(100%)",
    top: 0,
    left: "100%",
  },
  left: {
    transform: isOpen ? "translateX(100%)" : "translateX(-100%)",
    top: 0,
    right: "100%",
  },
  top: {
    transform: isOpen ? "translateY(100%)" : "translateY(-100%)",
    left: 0,
    bottom: "100%",
  },
  bottom: {
    transform: isOpen ? "translateY(-100%)" : "translateY(100%)",
    left: 0,
    top: "100%",
  },
});

export function Drawer({
  // children = null,
  isOpen = false,
  speed = 0.5,
  width = "30vw",
  height = "100vh",
  bgColor = "#fff",
  direction = "left",
  zIndex = 99,
  className = "drawer",
  style = {},
  onClick = () => {},
}: drawerProps) {
  // const { selectedFeature, updateFeatureOnTheMap } = useMap();

  const [editorType, setEditorType] = useState("Info");

  return (
    <div
      className={className}
      style={{
        position: "fixed",
        height: height,
        width: width,
        backgroundColor: bgColor,
        zIndex: zIndex,
        boxShadow: "0 0 15px 3px rgba(0, 0, 0, 0.1)",
        transition: `all ${speed}s ease`,
        ...directions(isOpen)[direction],
        ...style,
      }}
      data-testid="drawer"
    >
      <div style={{ position: "absolute", right: "10px", top: "10px", cursor:"pointer"}} onClick={onClick} >
        X
      </div>


      <div className="map-editor">
        {/* upper tabs to select Info or Style Tabs */}
        <div className="editor-tabs">
          <div className={editorType === "Info" ? "editor-tab editor-tab-clicked" : "editor-tab"} onClick={() => setEditorType("Info")}>
            Info
          </div>
          <div className={editorType === "Style" ? "editor-tab editor-tab-clicked" : "editor-tab"} onClick={() => setEditorType("Style")}>
            Style
          </div>
        </div>


        {/* Show the chosen Form */}
        {editorType === "Info" && <InfoEditor />}
        {editorType === "Style" && <StyleEditor />}
      </div>

      
      {/* {children} */}
    </div>
  );
}


// {selectedFeature && (
//   <div className="map-editor">
//     <h2>{selectedFeature.properties.name}</h2>
//     <button onClick={() => { updateFeatureOnTheMap("name", "new name", + selectedFeature.id); }}> change name </button>
//   </div>
// )}