// import React from "react";
import { useMap } from "../context";
import { LocalSaveButton } from "./mapEditor/LocalSaveButton";

function Nav() {
  const { toggleDrawerStatus, searchInput, handleSearchInput, searchFeatures } =
    useMap();

  return (
    <nav
      style={{
        zIndex: 20,
        top: "5px",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          margin: "5px 10px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          borderRadius: "15px",
          background: "#fefefe",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        }}
      >
        <button
          onClick={toggleDrawerStatus}
          style={{ border: "0px", background: "transparent", margin: "0" }}
        >
          <img src={"/assets/drawerBtn.svg"} alt="|||" />
        </button>
        <div>
          <input
            type="text"
            value={searchInput}
            onChange={(e) => handleSearchInput(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") searchFeatures();
            }}
          />
          <span
            className="fa fa-search"
            onClick={searchFeatures}
            style={{
              marginLeft: "-20px",
            }}
          ></span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            rowGap: "10vw",
          }}
        >
          {/* <LocalSaveButton /> */}
          <i className="fa fa-map"></i>
          {/* <Link to="/indoor">Indoor</Link> */}
        </div>
      </div>
    </nav>
  );
}

export default Nav;
