import "./MediaInput.css";

type NewMedia = {
  type: "image" | "video";
  file: File;
};

type Media = {
  type: "image" | "video";
  url: string;
};

type MediaInputProps = {
  media: Media[];
  newMedia?: NewMedia[];
  onChange: (value: Media[]) => void;
  onNewMediaChange: (newMedia: NewMedia[]) => void;
};

const UploadSVG = () => {
  return (
    <svg
      width={64}
      height={64}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M13.5 3H12H8C6.34315 3 5 4.34315 5 6V18C5 19.6569 6.34315 21 8 21H12M13.5 3L19 8.625M13.5 3V7.625C13.5 8.17728 13.9477 8.625 14.5 8.625H19M19 8.625V11.8125"
          stroke="#858585"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
        <path
          d="M17.5 21L17.5 15M17.5 15L20 17.5M17.5 15L15 17.5"
          stroke="#858585"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
};

const MediaInput = ({
  media,
  newMedia,
  onChange,
  onNewMediaChange,
}: MediaInputProps) => {
  const handleNewMediaUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const type = file.type.startsWith("image/") ? "image" : "video";
    onNewMediaChange([...(newMedia || []), { type, file }]);
  };

  const handleNewMediaRemove = (index: number) => {
    onNewMediaChange(newMedia?.filter((_, i) => i !== index) || []);
  };

  return (
    <form className="mediaForm input-element1">
      <label>Media</label>
      <div className="media">
        {media.map((item, i) => {
          return (
            <div className="mediaItem" key={i}>
              {item.type === "image" && <img src={item.url} alt="" />}
              {item.type === "video" && <video src={item.url} controls />}
              <button
                className="remove-button"
                onClick={(e) => {
                  e.preventDefault();
                  onChange(media.filter((_, index) => index !== i));
                }}
              >
                Remove
              </button>
            </div>
          );
        })}

        {/* pending */}
        {newMedia?.map((item, i) => {
          return (
            <div className="mediaItem mediaItemNew" key={i}>
              {item.type === "image" && (
                <img src={URL.createObjectURL(item.file)} alt="" />
              )}
              {item.type === "video" && (
                <video src={URL.createObjectURL(item.file)} controls />
              )}
              <button
                className="remove-button"
                onClick={(e) => {
                  e.preventDefault();
                  handleNewMediaRemove(i);
                }}
              >
                Remove
              </button>
            </div>
          );
        })}

        {/* add new item */}
        <div className="add-button">
          <label
            htmlFor="media-upload"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0",
            }}
          >
            <UploadSVG />
          </label>
          <input
            id="media-upload"
            type="file"
            accept="image/*, video/*"
            onChange={handleNewMediaUpload}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </form>
  );
};

export default MediaInput;
